import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { default as ReactDatePicker } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DateTime } from "luxon";
import { TimezoneProvider } from "@transficc/infrastructure";
import { Box } from "../../atoms";
import { CalendarIcon, Icon, IconNames } from "../../atoms/icon/icon";

export interface DatePickerProps {
    id: string;
    placeholderText: string;
    selectedEpochMillis: number | null;
    onChangeCallback: (epochMillis: number | null) => void;
    onEnterKeyPress?: () => void;
    timezoneProvider: TimezoneProvider;
    onIconClick?: () => void;
    iconVisible?: boolean;
}

const StyledDatePicker = styled(ReactDatePicker)`
    width: 11.5rem;
    height: 32px;
    align-content: center;
    background-color: transparent;
    color: white;
    text-indent: 8px;
    font-size: 0.875rem;
`;

export enum TimeZone {
    UTC = "UTC",
    CHICAGO = "America/Chicago",
    NEW_YORK = "America/New_York",
    TORONTO = "America/Toronto",
    BERLIN = "Europe/Berlin",
    LONDON = "Europe/London",
    TOKYO = "Asia/Tokyo",
}

export const DatePicker: React.FC<DatePickerProps> = ({
    id,
    placeholderText,
    selectedEpochMillis,
    onChangeCallback,
    onEnterKeyPress,
    timezoneProvider,
    onIconClick,
    iconVisible = false,
}) => {
    const browsersTimeZone = timezoneProvider.getTimezone();
    const [isFocused, setIsFocused] = useState(false);
    const [timezone, setTimezone] = useState<string>(browsersTimeZone);

    const convertToEpochMillis = (date: Date | null): number | null => {
        if (date !== null) {
            const fromJSDate = DateTime.fromObject(
                {
                    year: date.getFullYear(),
                    month: date.getMonth() + 1,
                    day: date.getDate(),
                    hour: date.getHours(),
                    minute: date.getMinutes(),
                    second: date.getSeconds(),
                },
                { zone: timezone },
            );
            return fromJSDate.toMillis();
        }
        return null;
    };

    const convertEpochMillisToDate = (epochMillis: number | null): Date | null => {
        if (epochMillis != null) {
            const zonedDateTime = DateTime.fromMillis(epochMillis, { zone: timezone });

            //take timezone converted values and pass them into date - datepicker component picks timezone-flavoured getters from JS timezone object.
            return new Date(zonedDateTime.year, zonedDateTime.month - 1, zonedDateTime.day, zonedDateTime.hour, zonedDateTime.minute);
        }
        return null;
    };

    const timeZoneList: JSX.Element[] = [
        <optgroup key={"browser-group"} label="DEFAULT">
            <option key={"browser-timezone"} value={browsersTimeZone}>
                {browsersTimeZone}
            </option>
        </optgroup>,
        <optgroup key={"common-group"} label="COMMON">
            {Object.values(TimeZone).map((tz) => (
                <option key={tz} value={tz}>
                    {tz}
                </option>
            ))}
        </optgroup>,
    ];

    const onKeyDownHandler = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
                e.currentTarget.blur();
                onEnterKeyPress?.();
            }
        },
        [onEnterKeyPress],
    );

    return (
        <Box
            width={"52"}
            contentDirection={"row"}
            contentAlignment={"center"}
            border={
                isFocused
                    ? { width: "px", color: { color: "warning" }, style: "solid" }
                    : { width: "px", color: { color: "white" }, style: "solid" }
            }
        >
            <StyledDatePicker
                id={id}
                showIcon
                selected={convertEpochMillisToDate(selectedEpochMillis)}
                onChange={(newDate: Date | null) => onChangeCallback(convertToEpochMillis(newDate))}
                dateFormat={"yyyy/MM/dd HH:mm"}
                showTimeSelect
                icon={CalendarIcon}
                placeholderText={placeholderText}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                onCalendarClose={() => setIsFocused(false)}
                onKeyDown={onKeyDownHandler}
            >
                <label htmlFor={id + "-timezone-select"}>Timezone:</label>
                <select
                    name="Timezone"
                    id={id + "-timezone-select"}
                    onChange={(event) => {
                        const selectedTimezone = event.currentTarget.options.item(event.currentTarget.selectedIndex)?.value;
                        setTimezone(selectedTimezone ?? browsersTimeZone);
                    }}
                    defaultValue={timezone}
                >
                    {timeZoneList}
                </select>
            </StyledDatePicker>
            <Box>
                <Icon
                    name={IconNames.Cross}
                    iconColor={iconVisible ? { color: "gray", level: "500" } : { color: "transparent" }}
                    size={"4"}
                    onClick={iconVisible ? onIconClick : undefined}
                />
            </Box>
        </Box>
    );
};
