import React from "react";
import { IconBaseProps } from "react-icons";
import styled from "styled-components";
import { ThemeUtils } from "../../theme";
import { TfiNewWindow } from "react-icons/tfi";

import {
    FaCheck,
    FaExclamationTriangle,
    FaEye,
    FaEyeSlash,
    FaHeart,
    FaHeartBroken,
    FaMinus,
    FaMoon,
    FaPlay,
    FaPlus,
    FaQuestionCircle,
    FaRegClock,
    FaRegPlayCircle,
    FaRegStar,
    FaRegStopCircle,
    FaStar,
    FaSun,
    FaSyncAlt,
    FaTimes,
    FaUser,
    FaVolumeMute,
    FaVolumeUp,
} from "react-icons/fa";
import { BsCaretDownSquareFill, BsCaretUpSquareFill, BsLockFill, BsPeopleFill, BsUnlock } from "react-icons/bs";
import { TbFilterOff } from "react-icons/tb";
import { MdTrendingUp } from "react-icons/md";
import { Box } from "../box";

export enum IconNames {
    Heart = "Heart",
    HeartBroken = "HeartBroken",
    Star = "Star",
    StarOutline = "StarOutline",
    VolumeOff = "VolumeOff",
    VolumeOn = "VolumeOn",
    Hide = "Hide",
    Show = "Show",
    Lock = "Lock",
    Unlock = "Unlock",
    Timer = "Timer",
    Increase = "Increase",
    Decrease = "Decrease",
    Check = "Check",
    Cross = "Cross",
    Plus = "Plus",
    Minus = "Minus",
    Warning = "Warning",
    User = "User",
    MultipleUsers = "MultipleUsers",
    UserQuestionMark = "UserQuestionMark",
    NewWindow = "NewWindow",
    Calendar = "Calendar",
    TrendUp = "TrendUp",
    Auto = "Auto",
    PlayCircle = "PlayCircle",
    Stop = "Stop",
    Play = "Play",
    Moon = "Moon",
    Sun = "Sun",
    FilterOff = "FilterOff",
}

// We need to export this so that it can be used as an SVG in the date picker React component. Can be used as an Icon elsewhere.
export const CalendarIcon: React.ReactElement = (
    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 119.92 122.88">
        <path
            fill="#FFFFFF"
            d="M108.68,122.88H11.24A11.28,11.28,0,0,1,0,111.64V22.55A11.28,11.28,0,0,1,11.24,11.31H21.61V25.14a12.35,12.35,0,0,0,4.67,9.61,14.55,14.55,0,0,0,18.31,0,12.35,12.35,0,0,0,4.67-9.61V11.31H70.2V25.14a12.35,12.35,0,0,0,4.67,9.61,14.55,14.55,0,0,0,18.31,0,12.35,12.35,0,0,0,4.67-9.61V11.31h10.83a11.3,11.3,0,0,1,11.24,11.24v89.09a11.27,11.27,0,0,1-11.24,11.24ZM83.58,56.77h16.1a2.07,2.07,0,0,1,2.06,2v13.4a2.07,2.07,0,0,1-2.06,2H83.58a2.06,2.06,0,0,1-2-2V58.82a2.05,2.05,0,0,1,2-2Zm-31.51,0H68.18a2.06,2.06,0,0,1,2,2v13.4a2.07,2.07,0,0,1-2,2H52.07a2.06,2.06,0,0,1-2-2V58.82a2.06,2.06,0,0,1,2-2Zm-31.84,0H36.34a2.06,2.06,0,0,1,2,2v13.4a2.07,2.07,0,0,1-2,2H20.23a2.06,2.06,0,0,1-2.05-2V58.82a2.05,2.05,0,0,1,2.05-2ZM83.58,85.26h16.1a2.07,2.07,0,0,1,2.06,2v13.4a2.06,2.06,0,0,1-2.06,2.05H83.58a2.06,2.06,0,0,1-2-2.05V87.31a2.06,2.06,0,0,1,2-2Zm-31.51,0H68.18a2.06,2.06,0,0,1,2,2v13.4a2.06,2.06,0,0,1-2,2.05H52.07a2.06,2.06,0,0,1-2-2.05V87.31a2.07,2.07,0,0,1,2-2Zm-31.84,0H36.34a2.06,2.06,0,0,1,2,2v13.4a2.06,2.06,0,0,1-2,2.05H20.23a2.06,2.06,0,0,1-2.05-2.05V87.31a2.06,2.06,0,0,1,2.05-2ZM78.6,4.45C78.6,2,81,0,84,0s5.43,2,5.43,4.45V25.14c0,2.46-2.42,4.45-5.43,4.45s-5.42-2-5.42-4.45V4.45ZM30,4.45C30,2,32.44,0,35.44,0s5.42,2,5.42,4.45V25.14c0,2.46-2.42,4.45-5.42,4.45S30,27.6,30,25.14V4.45ZM3.6,43.86v66.58a8.87,8.87,0,0,0,8.84,8.84h95a8.87,8.87,0,0,0,8.85-8.84V43.86Z"
        />
    </svg>
);

// Very specific requirements for the usage of this in Credit
const UserQuestionMarkIconContainer = styled(Box)`
    position: relative;

    & > *:nth-child(2) {
        position: absolute;
        right: -5%;
        bottom: -5%;
        height: 50%;
        width: 50%;
        background-color: white;
        border: 1px solid white;
        border-radius: 50%;
    }
`;
const UserQuestionMarkIcon: React.ReactElement = (
    <UserQuestionMarkIconContainer>
        <FaUser />
        <FaQuestionCircle />
    </UserQuestionMarkIconContainer>
);

const IconNameToComponentMap: Record<IconNames, React.FC<IconBaseProps>> = {
    [IconNames.Heart]: FaHeart,
    [IconNames.HeartBroken]: FaHeartBroken,
    [IconNames.Star]: FaStar,
    [IconNames.StarOutline]: FaRegStar,
    [IconNames.VolumeOff]: FaVolumeMute,
    [IconNames.VolumeOn]: FaVolumeUp,
    [IconNames.Hide]: FaEyeSlash,
    [IconNames.Show]: FaEye,
    [IconNames.Lock]: BsLockFill,
    [IconNames.Unlock]: BsUnlock,
    [IconNames.Timer]: FaRegClock,
    [IconNames.Increase]: BsCaretUpSquareFill,
    [IconNames.Decrease]: BsCaretDownSquareFill,
    [IconNames.Check]: FaCheck,
    [IconNames.Cross]: FaTimes,
    [IconNames.Plus]: FaPlus,
    [IconNames.Minus]: FaMinus,
    [IconNames.Warning]: FaExclamationTriangle,
    [IconNames.User]: FaUser,
    [IconNames.MultipleUsers]: BsPeopleFill,
    [IconNames.UserQuestionMark]: () => UserQuestionMarkIcon,
    [IconNames.NewWindow]: TfiNewWindow,
    [IconNames.Calendar]: () => CalendarIcon,
    [IconNames.TrendUp]: MdTrendingUp,
    [IconNames.Auto]: FaSyncAlt,
    [IconNames.PlayCircle]: FaRegPlayCircle,
    [IconNames.Stop]: FaRegStopCircle,
    [IconNames.Play]: FaPlay,
    [IconNames.Moon]: FaMoon,
    [IconNames.Sun]: FaSun,
    [IconNames.FilterOff]: TbFilterOff,
};

const IconContainer = styled.div<{ size: Theme.SpacingLevels; iconColor: Theme.Color; disabled?: boolean; isClickable?: boolean }>`
    width: ${(props) => props.theme.spacing[props.size]};
    height: ${(props) => props.theme.spacing[props.size]};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: ${(props) => (props.disabled ? "not-allowed" : props.isClickable ? "pointer" : "inherit")};
    background-color: transparent;

    & > *,
    & > * > * {
        color: ${(props) =>
            ThemeUtils.colorSelector(
                props.theme,
                props.disabled
                    ? {
                          color: "gray",
                          level: "400",
                      }
                    : props.iconColor,
            )};
        width: 100%;
        height: 100%;
    }
`;

export interface IconProps {
    name: IconNames;
    size: Theme.SpacingLevels;
    iconColor: Theme.Color;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    hoverTitle?: string;
    "data-testid"?: string;
}

export const Icon: React.FC<IconProps> = ({ name, iconColor, size, disabled, onClick, hoverTitle, ...props }) => {
    const IconComponent = IconNameToComponentMap[name];
    const isClickable = onClick !== undefined;
    return (
        <IconContainer
            size={size}
            iconColor={iconColor}
            disabled={disabled}
            isClickable={isClickable}
            as={isClickable ? "button" : "div"}
            onClick={disabled ? undefined : onClick}
            title={hoverTitle}
        >
            <IconComponent data-testid={props["data-testid"]} />
        </IconContainer>
    );
};
