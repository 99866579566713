// eslint-disable-next-line @nx/enforce-module-boundaries
import "../../../libs/components/src/lib/theme/theme-fonts";
import "bootstrap/dist/css/bootstrap.min.css";
import "./app/app.css";
import { StrictMode } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import * as ReactDOMClient from "react-dom/client";

import LogonPanel from "./app/LogonPanel";
import WaitingForExternalBrowserLogon from "./app/WaitingForExternalBrowserLogon";

const container = document.getElementById("root");
if (!container) {
    throw new Error("Failed to render page.");
}
const root = ReactDOMClient.createRoot(container);

root.render(
    <StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/waitingForExternalBrowserLogon" element={<WaitingForExternalBrowserLogon />}></Route>
                <Route path="*" element={<LogonPanel />}></Route>
            </Routes>
        </BrowserRouter>
    </StrictMode>,
);
