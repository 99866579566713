import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";

import styles from "./LogonPanel.module.css";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

const WaitingForExternalBrowserLogon: React.FunctionComponent = () => {
    const location = useLocation();
    const query = queryString.parse(location.search);

    const fallbackUri = query["fallback_uri"];
    return (
        <Container className={styles["container"]}>
            <Row className="justify-content-md-center">
                <Col>
                    <div className={(styles["header"] ?? "") + " text-center"}>
                        <h1>TransFICC eTrading</h1>
                    </div>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col className="justify-content-center">
                    <p>Waiting for logon to complete in external Browser Window...</p>
                </Col>
            </Row>
            {fallbackUri ? (
                <Row className="justify-content-md-center">
                    <Col className="justify-content-center">
                        <a href={decodeURI(fallbackUri as string)}>Click here if you aren't redirected</a>
                    </Col>
                </Row>
            ) : (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <></>
            )}
        </Container>
    );
};

export default WaitingForExternalBrowserLogon;
