import styled from "styled-components";
import { ThemeUtils } from "../../theme";
import React from "react";

export interface BoxProps {
    children?: React.ReactNode;
    backgroundColor?: Theme.Color;

    width?: Theme.SpacingLevels;
    minWidth?: Theme.SpacingLevels;
    height?: Theme.SpacingLevels;
    minHeight?: Theme.SpacingLevels;

    rounded?: boolean;
    disabled?: boolean;

    margin?: Theme.SpacingLevels;
    marginT?: Theme.SpacingLevels;
    marginR?: Theme.SpacingLevels;
    marginB?: Theme.SpacingLevels;
    marginL?: Theme.SpacingLevels;

    padding?: Theme.SpacingLevels;
    paddingT?: Theme.SpacingLevels;
    paddingR?: Theme.SpacingLevels;
    paddingB?: Theme.SpacingLevels;
    paddingL?: Theme.SpacingLevels;

    border?: ThemeUtils.BorderType;
    borderT?: ThemeUtils.BorderType;
    borderR?: ThemeUtils.BorderType;
    borderB?: ThemeUtils.BorderType;
    borderL?: ThemeUtils.BorderType;

    contentDirection?: "row" | "row-reverse" | "column" | "column-reverse";
    contentAlignment?: "start" | "center" | "end";
    contentJustification?: "start" | "center" | "end" | "space-between" | "space-around" | "space-evenly";
    selfAlignment?: "start" | "center" | "end";
    textAlign?: "left" | "center";

    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const Box = styled.div<BoxProps>`
    background-color: ${({ backgroundColor, theme }) =>
        backgroundColor ? ThemeUtils.colorSelector(theme, backgroundColor) : "transparent"};

    ${(props) => (props.disabled ? "cursor: not-allowed" : "")};

    width: ${(props) => (props.width ? props.theme.spacing[props.width] : "initial")};
    min-width: ${(props) => (props.minWidth ? props.theme.spacing[props.minWidth] : "initial")};
    height: ${(props) => (props.height ? props.theme.spacing[props.height] : "initial")};
    min-height: ${(props) => (props.minHeight ? props.theme.spacing[props.minHeight] : "initial")};

    border-radius: ${(props) => (props.rounded ? props.theme.borderRadius : "0")};

    ${(props) => (props.margin ? `margin: ${props.theme.spacing[props.margin]};` : "")}
    ${(props) => (props.marginT ? `margin-top: ${props.theme.spacing[props.marginT]};` : "")}
    ${(props) => (props.marginR ? `margin-right: ${props.theme.spacing[props.marginR]};` : "")}
    ${(props) => (props.marginB ? `margin-bottom: ${props.theme.spacing[props.marginB]};` : "")}
    ${(props) => (props.marginL ? `margin-left: ${props.theme.spacing[props.marginL]};` : "")}

    ${(props) => (props.padding ? `padding: ${props.theme.spacing[props.padding]};` : "")}
    ${(props) => (props.paddingT ? `padding-top: ${props.theme.spacing[props.paddingT]};` : "")}
    ${(props) => (props.paddingR ? `padding-right: ${props.theme.spacing[props.paddingR]};` : "")}
    ${(props) => (props.paddingB ? `padding-bottom: ${props.theme.spacing[props.paddingB]};` : "")}
    ${(props) => (props.paddingL ? `padding-left: ${props.theme.spacing[props.paddingL]};` : "")}

    ${(props) => (props.border ? `border: ${ThemeUtils.buildBorder(props.theme, props.border)};` : "")}
    ${(props) => (props.borderT ? `border-top: ${ThemeUtils.buildBorder(props.theme, props.borderT)};` : "")}
    ${(props) => (props.borderR ? `border-right: ${ThemeUtils.buildBorder(props.theme, props.borderR)};` : "")}
    ${(props) => (props.borderB ? `border-bottom: ${ThemeUtils.buildBorder(props.theme, props.borderB)};` : "")}
    ${(props) => (props.borderL ? `border-left: ${ThemeUtils.buildBorder(props.theme, props.borderL)};` : "")}

    display: flex;
    flex-direction: ${(props) => props.contentDirection ?? "column"};
    align-items: ${(props) => props.contentAlignment ?? "initial"};
    justify-content: ${(props) => props.contentJustification ?? "start"};
    ${(props) => (props.textAlign ? `text-align: ${props.textAlign};` : "")};
    ${(props) => (props.selfAlignment ? `align-self: ${props.selfAlignment};` : "")};
`;
