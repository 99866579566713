import * as React from "react";

export interface ErrorBoundaryProps {
    children: React.ReactNode;
    renderError: (reason: string) => React.ReactNode;
    reportError: (err: Error, errorInfo: React.ErrorInfo) => unknown;
}

interface State {
    hasError: boolean;
    reason?: string;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, State> {
    public override state: State = {
        hasError: false,
    };

    public static getDerivedStateFromError(error: Error): State {
        return { hasError: true, reason: error.message };
    }

    public override componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        this.props.reportError?.(error, errorInfo);
    }

    public override render(): React.ReactNode {
        if (this.state.hasError) {
            return this.props.renderError(this.state.reason ?? "unknown reason");
        }

        return this.props.children;
    }
}
