import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { Alert, Button, Col, Container, FormControl, InputGroup, Row } from "react-bootstrap";
import queryString from "query-string";

import styles from "./LogonPanel.module.css";
import { TransficcLogo } from "@transficc/components";

interface ExternalIdpJsonResponse {
    displayName: string | null;
}

const LogonPanelForm: React.FunctionComponent<{ externalIdpInformation: ExternalIdpJsonResponse }> = ({ externalIdpInformation }) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [showSignInForm, setShowSignInForm] = useState(true);

    useEffect(() => {
        const oauthError = queryString.parseUrl(window.location.href).query["error"];
        const oauthErrorDescription = queryString.parseUrl(window.location.href).query["error_description"];
        let oauthErrorMessage = oauthError as string;
        if (oauthErrorDescription && typeof oauthErrorDescription === "string") {
            oauthErrorMessage = decodeURI(oauthErrorDescription);
        }
        setErrorMessage(oauthErrorMessage);
    }, []);

    useEffect(() => {
        setShowSignInForm(externalIdpInformation.displayName == null);
    }, [externalIdpInformation]);

    const handleShowForm = useCallback(() => {
        setShowSignInForm(!showSignInForm);
    }, [showSignInForm]);

    const customerId = queryString.parseUrl(window.location.href).query["customer_id"];
    const redirectUri = queryString.parseUrl(window.location.href).query["redirect_uri"];
    const workflowId = queryString.parseUrl(window.location.href).query["workflow_id"];

    const requiredParamsSet = redirectUri && workflowId;

    const clickToLogonButton = (
        <Alert data-testid="logon-message" variant="warning">
            Go to URL starting with <strong>trader</strong> rather than <strong>idp</strong>
        </Alert>
    );

    const errorMessageElement = (
        <Alert data-testid="logon-message" variant="danger">
            {errorMessage}
        </Alert>
    );
    const signInFormElement = (
        <form action="/api/logon" method="post" className={styles["form"]}>
            <input type="hidden" name="customerid" value={customerId && typeof customerId === "string" ? customerId : ""} />
            <input type="hidden" name="redirecturi" value={redirectUri && typeof redirectUri === "string" ? redirectUri : ""} />
            <input type="hidden" name="workflowid" value={workflowId && typeof workflowId === "string" ? workflowId : ""} />
            <InputGroup>
                <FormControl
                    autoComplete="username"
                    data-testid="username"
                    placeholder="Username"
                    aria-label="Username"
                    aria-describedby="username-description"
                    name="username"
                />
            </InputGroup>
            <InputGroup>
                <FormControl
                    autoComplete="password"
                    data-testid="password"
                    placeholder="Password"
                    aria-label="Password"
                    aria-describedby="basic-addon1"
                    type="password"
                    name="password"
                />
            </InputGroup>
            <div className="text-center">
                <Button data-testid="logon" type="submit" className={styles["submit"]}>
                    Sign In
                </Button>
            </div>
        </form>
    );

    const externalIdpSignInForm = (
        <>
            <div className="text-center">
                {showSignInForm && <p className={styles["or"]}>or</p>}
                <form action="/api/external-identity-provider" method="post" className={styles["form"]}>
                    <input type="hidden" name="customerId" value={customerId && typeof customerId === "string" ? customerId : ""} />
                    <input type="hidden" name="redirectUri" value={redirectUri && typeof redirectUri === "string" ? redirectUri : ""} />
                    <input type="hidden" name="workflowId" value={workflowId && typeof workflowId === "string" ? workflowId : ""} />
                    <Button data-testid="logon-sso" type="submit" className={styles["submit-sso"]}>
                        Sign in with {externalIdpInformation.displayName}
                    </Button>
                </form>
            </div>
            {!showSignInForm && (
                <div className="text-center">
                    <p className={styles["show-logon"]}>
                        <Button data-testid="show-logon-form" type="button" className={styles["submit-sso"]} onClick={handleShowForm}>
                            Sign in with TransFICC
                        </Button>
                    </p>
                </div>
            )}
        </>
    );

    return (
        <>
            {errorMessage ? errorMessageElement : null}
            {!requiredParamsSet ? clickToLogonButton : null}
            {requiredParamsSet && showSignInForm && signInFormElement}
            {requiredParamsSet && externalIdpInformation.displayName && externalIdpSignInForm}
        </>
    );
};
const LogonPanel: React.FunctionComponent = () => {
    const [externalIdpInformation, setExternalIdpInformation] = useState<ExternalIdpJsonResponse | null>(null);
    const [fetchingExternalIdpError, setFetchingExternalIdpError] = useState<string | null>(null);

    useEffect(() => {
        const urlObject: { url: string; query?: Record<string, string> } = {
            url: window.location.origin + "/api/external-identity-provider",
        };

        const customerId = queryString.parseUrl(window.location.href).query["customer_id"];

        if (customerId && typeof customerId === "string") {
            urlObject.query = { customerId: customerId };
        }

        fetch(queryString.stringifyUrl(urlObject), {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        })
            .then((response) => response.json())
            .then((response) => {
                setExternalIdpInformation(response as ExternalIdpJsonResponse);
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.error(err);
                setFetchingExternalIdpError("Something went wrong while fetching external identity provider information");
            });
    }, []);

    return (
        <Container className={styles["container"]}>
            <Row>
                <Col>
                    <div className={styles["header"]}>
                        <TransficcLogo />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    {fetchingExternalIdpError && <p>{fetchingExternalIdpError}</p>}
                    {externalIdpInformation ? (
                        <LogonPanelForm externalIdpInformation={externalIdpInformation} />
                    ) : (
                        <p>Loading external identity provider information...</p>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default LogonPanel;
